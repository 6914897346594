import React, { useState } from "react";
import styles from "./activityLog.module.scss";
import Grid from "./grid/grid";
import { url } from "config.js";
import ImageBox from "components/image-box/image-box";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handler

  function onSelectionChanged(e) {
    setState(initialState);
    setState(e.selectedRowsData[0] || initialState);
  }

  //
  // render

  return (
    <div className={styles.main}>
      <div className={styles.grid}>
        <Grid id={props.id} selectedRowsData={props.selectedRowsData} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.gallery}>{state.id && <ImageBox url={`${url}/activitylogimage/activitylog/${state.id}`} />}</div>
    </div>
  );
}

export default Component;
