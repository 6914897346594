import React from "react";
import { connect } from "react-redux";
import "./user-panel.scss";

function UserPanel(props) {
  //
  // const

  const avatarUrl = props.avatarUrl || `https://stirisprod001.blob.core.windows.net/avatars/Avatar.jpg`;

  // useHistory

  return (
    <div className="components-user-panel">
      <div className="user-name">{props.name}</div>
      <div className="image-container">
        <img className="image" alt="/" src={avatarUrl} />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    name: state.user.name || "",
    avatarUrl: state.user.avatarUrl,
  };
}

export default connect(mapStateToProps, null)(UserPanel);
