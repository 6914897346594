import React from "react";
import { Route, Switch } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./lookup.module.scss";
import { selectedTabItem } from "helper/global";
import { dict } from "helper/global";
import ImpactFactor from "./impactfactor/impactfactor.js";
import BusinessValue from "./businessvalue/businessvalue.js";
import AbsFactor from "./absfactor/absfactor.js";
import RFactor from "./rfactor/rfactor.js";

function Component(props) {
  const tabItems = [
    {
      text: dict("Impact Factor"),
      icon: "fad fa-hammer",
      path: "/impactfactor",
    },
    {
      text: dict("Business Value"),
      icon: "fad fa-industry",
      path: "/businessvalue",
    },
    {
      text: dict("Abs Factor"),
      icon: "fad fa-bone-break",
      path: "/absfactor",
    },
    {
      text: dict("R Factor"),
      icon: "fad fa-ellipsis-h",
      path: "/rfactor",
    },
  ];

  function changeView(e) {
    props.history.push(`/klient/cof/lookup${e.path}`);
  }

  return (
    <div className={styles.main}>
      <div className={`${styles.tabs} `}>
        <Tabs
          tabItems={tabItems}
          selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)}
          changeView={changeView}
          className={"dx-theme-border-color"}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient", "/klient/cof", "/klient/cof/lookup", "/klient/cof/lookup/impactfactor"]} component={ImpactFactor} />
          <Route exact path={["/klient/cof/lookup/businessvalue"]} component={BusinessValue} />
          <Route exact path={["/klient/cof/lookup/absfactor"]} component={AbsFactor} />
          <Route exact path={["/klient/cof/lookup/rfactor"]} component={RFactor} />
        </Switch>
      </div>
    </div>
  );
}

export default Component;
