import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { notify } from "helper/global";

export const startJob = (options) => {
  const { name, url, params } = options;
  return async (dispatch, getState) => {
    if (getState().job.name) {
      notify("Another operation is already in progress", "error");
      return;
    }

    try {
      var result = await request({ method: "Post", url, params });
      dispatch({ type: actionTypes.JOB_START, name: name, id: result.id });
    } catch (error) {
      notify("Operation Unsuccessful", "error");
    }

    return "done";
  };
};

export function endJob() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.JOB_END });
    } catch (error) {
      notify("Operation Unsuccessful", "error");
    }

    return "done";
  };
}
