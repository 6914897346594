import React from "react";
import styles from "./job.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import { withMemo } from "helper/global";

function Compnent(props) {
  //
  // stores

  const jobStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/kon/job/type/${props.typeId}`,
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",

      options: {
        hint: "Sync",
        icon: "fas fa-play",
        onClick: props.onRun,
      },
    });
  }

  return (
    <div className={styles.main}>
      <Grid
        title={"Job"}
        dataSource={jobStore}
        showButtons={false}
        selection={{ mode: "single" }}
        resetRowSelectionOnLoad={true}
        onSelectionChanged={props.onSelectionChanged}
        allowExpanding={false}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={false}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Column caption={"Id"} dataField={"id"} visible={false} sortOrder={"desc"} />
        <Column caption={"Name"} dataField={"name"} />
      </Grid>
    </div>
  );
}

export default withMemo(Compnent, []);
