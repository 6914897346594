export const navigation = [
  {
    text: "Condition",
    icon: "fad fa-sunglasses",
    items: [
      {
        text: "Lookup",
        path: "/coa/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Input",
        path: "/coa/input",
        icon: "fad fa-keyboard",
      },
      {
        text: "Admin",
        path: "/coa/admin",
        icon: "fad fa-users-crown",
      },
    ],
  },
];
