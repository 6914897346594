import React, { useState } from "react";
import styles from "./user.module.scss";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Editing, FilterRow, Lookup, Popup, Position } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import Policy from "./policy/policy";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.user}>
        <User onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.policy}>{state.id && <Policy userId={state.id} />}</div>
    </div>
  );
}
export default Component;

const User = withMemo((props) => {
  //
  // stores

  const userStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/cof/user`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/cof/user`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/cof/user`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/cof/user/${id}`, method: "Delete" });
      },
    }),
  };

  const userLookupStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cof/user/lookup`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cof/user/lookup/${id}` });
      },
    }),
  };

  // const

  const roles = [
    { id: 0, name: "User" },
    { id: 1, name: "Admin" },
  ];

  // render

  return (
    <div className={styles.main}>
      <Grid
        dataSource={userStore}
        title={"User"}
        editingMode={"row"}
        selection={{ mode: "single" }}
        rowNumbering={true}
        allowDownloading={false}
        onSelectionChanged={props.onSelectionChanged}
      >
        <Editing mode={"popup"} allowUpdating={true} allowDeleting={true}>
          <Popup title="User" showTitle={true} width={700} height={700}>
            <Position my="center" at="center" of={window} />
          </Popup>
        </Editing>
        <FilterRow visible={true} />
        <Column caption={"UserName"} dataField={"id"} sortOrder={"desc"}>
          <Lookup dataSource={userLookupStore} valueExpr={"id"} displayExpr="userName" />
          <RequiredRule />
        </Column>
        <Column caption={"Role"} dataField={"roleId"} width={200}>
          <Lookup dataSource={roles} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
      </Grid>
    </div>
  );
}, []);
