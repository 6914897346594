import React, { useContext, useRef, useMemo } from "react";
import styles from "./cofscenarionode.module.scss";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import ProgressPanel from "components/progress-panel/progress-panel";
import HierarchyContext from "components/hierarchy/context";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid.js";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import Hierarchy from "components/hierarchy/hierarchy";
import { confirm } from "devextreme/ui/dialog";
import { notify, dict } from "helper/global";
import DropDown from "components/grid-dropdown/grid-dropdown";

function Component(props) {
  //
  // hooks
  const selected = useRef(null);

  // event handlers

  async function onSave() {
    var result = await confirm("<i>Do you really want to save the entire hierarchy?</i>", "Confirm");
    if (result) {
      try {
        props.run({ name: "RUN", url: `${url}/cof/save` });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  async function onSync() {
    var result = await confirm("<i>Do you really want to sync the entire hierarchy?</i>", "Confirm");
    if (result) {
      try {
        props.run({ name: "RUN", url: `${url}/cof/sync` });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  async function onNodeSave() {
    var result = await confirm("<i>Do you really want to sync the selected hierarchy?</i>", "Confirm");
    if (result) {
      try {
        props.run({ name: "RUN", url: `${url}/cof/save`, params: { ids: selected.current.map((i) => i.id) } });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  function onSelectionChanged(e) {
    selected.current = e.selectedRowsData;
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push(
      {
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Sync",
          icon: "fas fa-sync",
          onClick: onSync,
        },
      },
      {
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Save",
          icon: "fas fa-save",
          onClick: onSave,
        },
      }
    );
  }

  function onContextMenuPreparing(e) {
    e.items.push({
      text: "Save",
      icon: "fas fa-save",
      onItemClick: onNodeSave,
    });
  }

  return (
    <div className={styles.main}>
      <Hierarchy
        addButton={false}
        allowEditing={false}
        allowDragging={false}
        multiSelect={true}
        countUrl={`${url}/node/count`}
        onSelectionChanged={onSelectionChanged}
        onToolbarPreparing={onToolbarPreparing}
        onContextMenuPreparing={onContextMenuPreparing}
        showFilter={false}
        showCount={true}
        type={1}
      >
        <ProgressPanel name={"RUN"}>
          <ClassNode />
        </ProgressPanel>
      </Hierarchy>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const ClassNode = connect(
  null,
  mapDispatchToProps
)((props) => {
  // stores

  const classNodeStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (selectedRowsData.length === 0) return Promise.resolve([]);
        return request({
          url: `${url}/cof/scenarionode/node`,
          params: { filterExpression: filter, ids: selectedRowsData.map((i) => i.id) },
          loadOptions: loadOptions,
        });
      },
      insert: (data) => {
        return request({ url: `${url}/cof/scenarionode`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/cof/scenarionode`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/cof/scenarionode/${id}`, method: "Delete" });
      },
    }),
  };

  const businessValueStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cof/businessvalue`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cof/businessvalue/${id}` });
      },
    }),
  };

  const absFactorStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cof/AbsFactor`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cof/AbsFactor/${id}` });
      },
    }),
  };

  const rFactorStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cof/RFactor`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cof/RFactor/${id}` });
      },
    }),
  };

  // hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;
  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.run({ name: "UPLOAD_COFSCENARIONODE", url: `${url}/cof/scenarionode/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("weightedCof", "visible", false);
    e.component.columnOption("status", "visible", false);
    e.component.columnOption("maxCof", "visible", false);
    e.component.columnOption("cof", "visible", false);
    // e.component.columnOption("cofBusinessValue", "visible", false);
    // e.component.columnOption("cofBusinessValueId", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "undefined");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("weightedCof", "visible", true);
    e.component.columnOption("status", "visible", true);
    e.component.columnOption("maxCof", "visible", true);
    e.component.columnOption("cof", "visible", true);
    // e.component.columnOption("cofBusinessValue", "visible", true);
    // e.component.columnOption("cofBusinessValueId", "visible", false);
    exporting.current = false;
  }

  function onCellPrepared(e) {
    if (e.rowType === "data" && e.column.dataField === "status" && e.data.status === "Out-of-Date") {
      e.cellElement.style.color = "red";
    }
  }

  function calculateBusinessValue(e) {
    if (exporting.current) return "exporting";
    else return e.cofBusinessValue;
  }

  // render

  function dropDownRender(data) {
    return (
      <DropDown data={data} showColumnHeaders={true} height={300} width={650}>
        <Column dataField="name" width={150}></Column>
        <Column dataField="description" width={400}></Column>
        <Column dataField="value" width={75} alignment={"left"}></Column>
      </DropDown>
    );
  }

  const gridRender = useMemo(() => {
    return (
      <div className={styles.main}>
        <Grid
          title={"Weighted Cof"}
          showTitle={false}
          dataSource={classNodeStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
          allowAdding={false}
          allowDeleting={false}
          onCellPrepared={onCellPrepared}
        >
          <Column visible={false} dataField={"id"} allowEditing={false} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            name={"operation"}
            calculateCellValue={() => "Update"}
            width={100}
            visible={false}
            allowEditing={false}
            formItem={{ visible: false }}
          />
          <Column caption={"AssetId"} dataField={"name"} width={200} formItem={{ editorOptions: { readOnly: true } }} />
          <Column caption={"Description"} dataField={"description"} width={200} formItem={{ editorOptions: { readOnly: true } }} />
          <Column caption={"Class"} dataField={"class"} formItem={{ editorOptions: { readOnly: false } }} width={150}></Column>
          <Column caption={"Max Cof"} dataField={"maxCof"} dataType={"number"} width={100} formItem={{ visible: false }} />
          {/* <Column caption={"Business Value"} dataField={"cofBusinessValue"} width={150} formItem={{ visible: false }} /> */}
          <Column
            caption={"Business Value"}
            dataField={"businessValueId"}
            calculateDisplayValue={"businessValue"}
            editCellRender={dropDownRender}
            //calculateCellValue={calculateBusinessValue}
            width={150}
          >
            <Lookup dataSource={businessValueStore} valueExpr={"id"} displayExpr="name" />
          </Column>
          <Column caption={"Abs Factor"} dataField={"absFactorId"} calculateDisplayValue="absFactor" width={150} editCellRender={dropDownRender}>
            <Lookup dataSource={absFactorStore} valueExpr={"id"} displayExpr="name" />
          </Column>
          <Column caption={"R Factor"} dataField={"rFactorId"} calculateDisplayValue="rFactor" width={200} editCellRender={dropDownRender}>
            <Lookup dataSource={rFactorStore} valueExpr={"id"} displayExpr="name" />
          </Column>
          <Column
            caption={"Weighted Cof"}
            dataField={"weightedCof"}
            dataType={"number"}
            format="0#"
            width={100}
            formItem={{ visible: false, editorOptions: { readOnly: true } }}
          />
          <Column
            caption={"IRIS Cof"}
            dataField={"cof"}
            dataType={"number"}
            format="#"
            width={100}
            formItem={{ visible: false, editorOptions: { readOnly: true } }}
          />
          <Column caption={"Status"} dataField={"status"} width={100} formItem={{ visible: false, editorOptions: { readOnly: true } }} />
          {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
          <Column
            caption={"Notes"}
            dataField={"notes"}
            allowFiltering={true}
            width={400}
            formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
          />
        </Grid>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData]);

  return selectedRowsData.length > 0 && <ProgressPanel name="UPLOAD_COFSCENARIONODE">{gridRender}</ProgressPanel>;
});
