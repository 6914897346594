import React from "react";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import Grid from "components/data-grid/data-grid";
import { Column } from "devextreme-react/data-grid";

function Policy(props) {
  //
  // store

  const policyStore = {
    store: new CustomStore({
      load: (loadOptions) => {
        return request({ url: `${url}/kon/userpolicy/${props.userId}`, loadOptions: loadOptions });
      },
      update: (key, data) => {
        return request({
          url: `${url}/kon/userpolicy`,
          method: "Put",
          data: { userId: props.userId, ...data },
        });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Policy"}
      dataSource={policyStore}
      editingMode="batch"
      allowAdding={false}
      allowUpdating={true}
      allowDeleting={false}
      rowNumbering={true}
      allowDownloading={false}
      allowFiltering={false}
      allowSorting={false}
    >
      <Column caption="Name" dataField="name" allowSorting={false}></Column>
      <Column caption="Permission" dataField="permission" width={200} allowFiltering={false} allowSorting={false}></Column>
    </Grid>
  );
}

export default Policy;
