import React, { useEffect, useState } from "react";
import { Circle } from "rc-progress";
import { Button } from "devextreme-react";
import "./progress.scss";
import { url } from "config.js";
import { request } from "helper/http-client";

function Component(props) {
  //
  //  useState
  const [progress, setProgress] = useState({ value: 0, description: null });

  // useEffect

  useEffect(() => {
    var interval = setInterval(async () => {
      const result = await request({ url: `${url}/progress/${props.progressId}` });
      if (result) {
        setProgress(result);
      } else props.onComplete && props.onComplete();
    }, 1000);
    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useRef

  // event handlers

  function onCancel() {
    props.onCancel && props.onCancel();
  }

  // render

  return (
    <div className={"components_progress"}>
      <div className={"components_progress_container"}>
        <div className={"components_progress_circle"}>
          <Circle percent={progress.value || 0} strokeWidth="6" strokeLinecap="round" strokeColor={"#98c21f"} />
        </div>
        <div className={"components_progress_circle"}>{`${progress.value || 0}%`}</div>
        <div className={"components_progress_button"}>
          <Button height={25} width={35} text="Cancel" type="normal" stylingMode="outlined" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
}

export default Component;
