import React from "react";
import { withMemo, currency } from "helper/global";
import Grid from "components/data-grid/data-grid";
import { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";

function Component() {
  //
  // stores
  const datasetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/kon/con/dataset`,
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Dataset"}
      dataSource={datasetStore}
      allowExpanding={true}
      showButtons={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
    >
      <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
      <Column caption={"AssetId"} dataField={"name"} width={300} />
      <Column caption={"Description"} dataField={"description"} width={200} />
      <Column caption={"Date"} dataField={"date"} dataType={"date"} format={"yyyy-MM-dd"} width={100} />
      <Column caption={"Ogen"} dataField={"ogen"} width={100} />
      <Column caption={"bolders"} dataField={"bolders"} width={100} />
      <Column caption={"Trappen"} dataField={"trappen"} width={100} />
      <Column caption={"Kabelring"} dataField={"kabelring"} width={100} />
      <Column caption={"Drijflichaam"} dataField={"drijflichaam"} width={100} />
      <Column caption={"Creator"} dataField={"creator"} width={150} />
      <Column caption={"CreatedDate"} dataField={"createdDate"} dataType={"date"} format={"yyyy-MM-dd"} width={125} />
      <Column caption={"Editor"} dataField={"editor"} width={150} />
      <Column caption={"EditedDate"} dataField={"editedDate"} dataType={"date"} format={"yyyy-MM-dd"} width={125} />
      <Column caption={"Email"} dataField={"email"} width={300} />
      <Column caption={"Notes"} dataField={"notes"} width={400} />
      <Column caption={"LogId"} dataField={"logId"} width={100} />
    </Grid>
  );
}

export default Component;
