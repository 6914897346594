import React from "react";
import Asset from "./asset/asset";
import Hierarchy from "components/hierarchy/hierarchy";
import { url } from "config.js";

function Component(props) {
  return (
    <Hierarchy
      allowEditing={false}
      allowDragging={false}
      showCount={true}
      showFilter={false}
      countUrl = {`${url}/node/count`}
    >
      <Asset />
    </Hierarchy>
  );
}

export default Component;
