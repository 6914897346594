import React, { useState, useContext, useRef } from "react";
import styles from "./asset.module.scss";
import HierarchyContext from "components/hierarchy/context";
import ActivityLog from "../activityLog/activityLog";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { dict, currency, withMemo } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid.js";
import MultiEditor from "components/multi-editor/multi-editor";
import Summary from '../summary/summary'

function Component(props) {
  //
  // useStore

  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const [editor, setEditor] = useState(false);
  const loadOptions = useRef(null);

  // useContext

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  function onEditor() {
    setEditor(true);
  }

  function onLoadOptions(e) {
    loadOptions.current = e;
  }

  // render

  function activityLog() {
    return state.id && <ActivityLog id={state.id} selectedRowsData={selectedRowsData} assetId={state.assetId} />;
  }

  function summaryRender() {
    return state.id && <Summary scenarioNodeId={state.id} assetId={state.assetId} nodeTypeId={state.nodeTypeId} />;
  }

  function detailsRender() {
    return (
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={dict("Summary")} render={summaryRender} />
        <Item title={dict("Activity")} render={activityLog} />
      </TabPanel>
    );
  }

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <div className={styles.item1}>
          <Asset
            selectedRowsData={selectedRowsData}
            filter={filter}
            onSelectionChanged={onSelectionChanged}
            onEditor={onEditor}
            onLoadOptions={onLoadOptions}
          />
        </div>
        <div className={styles.item2}>{state && detailsRender()}</div>
      </div>
    )
  );
}

// redux

export default Component;

function mapStateToProps(state) {
  return {
    multiEditNode: !!state.user.accessMultiEditNode,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const Asset = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      // variables

      var disabledForProgram = ["type", "size", "material", "manufacturer", "model", "count", "installYear", "value", "usefulLife", "installYear"];

      //
      // stores

      const assetStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            props.onLoadOptions(loadOptions);
            return request({
                  url: `${url}/scenarionode`,
                  loadOptions: loadOptions,
                  params: { ids: props.selectedRowsData.map((i) => i.id), filterExpression: props.filter, types: ["Asset", "SuperAsset", "Program"] },
                });
          },
        }),
      };

      // hooks

      function onSelectionChanged(e) {
        props.onSelectionChanged && props.onSelectionChanged(e);
      }

      function onCellPrepared(e) {
        if (e.rowType === "data" && disabledForProgram.some((i) => i === e.column.dataField) && e.data.nodeTypeId === 3) {
          e.cellElement.style.background = "#44570E";
          e.cellElement.style.color = "#292929";
        }
      }

      function onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
          if (disabledForProgram.some((i) => i === e.dataField)) {
            if (e.row.data.nodeTypeId === 3) {
              e.editorOptions.disabled = true;
            }
          }
        }
      }

      // render

      return (
        <ProgressPanel name={"UPLOAD_SCENARIONODE"}>
          <div className={styles.main}>
            <Grid
              title={"Asset"}
              dataSource={assetStore}
              selection={{ mode: "single" }}
              allowAdding={false}
              allowUpdating={false}
              allowDeleting={false}
              allowDownloading = {false}
              onSelectionChanged={onSelectionChanged}
              onCellPrepared={onCellPrepared}
              onEditorPreparing={onEditorPreparing}
              allowExpanding={true}
            >
              <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
              <Column caption={"Parent"} dataField={"parent"} allowEditing={false} width={200} visible={false} formItem={{ visible: false }} />
              <Column caption={"Node Type"} dataField={"nodeTypeId"} visible={false} formItem={{ visible: false }} />
              <Column caption={"AssetId"} dataField={"name"} allowEditing={false} width={200} />
              <Column caption={"Description"} dataField={"description"} allowEditing={false} width={400} />
              <Column caption={"Location"} dataField={"location"} dataType={"string"} allowEditing={false} width={200} />
              <Column caption={"Ownership"} dataField={"ownership"} width={200} allowEditing={false} />
              <Column caption={"Class"} dataField={"class"} allowEditing={false} width={200} />
              <Column caption={"Type"} dataField={"type"} allowEditing={false} width={200} />
              <Column caption={"Size"} dataField={"size"} allowEditing={false} width={200} />
              <Column caption={"Material"} dataField={"material"} allowEditing={false} width={200} />
              <Column caption={"Manufacturer"} dataField={"manufacturer"} allowEditing={false} width={200} />
              <Column caption={"Model"} dataField={"model"} dataType={"string"} allowEditing={false} width={200} />
              <Column caption={"Count"} dataField={"count"} allowEditing={false} width={200} />
              <Column caption={"Install Year"} dataField={"installYear"} dataType={"number"} allowEditing={false} width={200} />
              <Column
                caption={"Potential Useful Life"}
                dataField={"potentialUsefulLife"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column caption={"Strategy"} dataField={"strategyId"} calculateDisplayValue="strategy" width={200}>
              </Column>
              <Column caption={"Cost"} dataField={"costId"} calculateDisplayValue="cost" width={200}>
              </Column>
              <Column
                caption={"Cost Override"}
                dataField={"costOverride"}
                dataType={"number"}
                width={100}
                format={`${currency()}#,###`}
                formItem={{ editorOptions: { format: `${currency()}#,###`, showClearButton: true } }}
                
              >
              </Column>

              <Column
                caption={"Pof Override"}
                dataField={"pofOverride"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column caption={"Cof"} dataField={"cof"} dataType={"number"} width={100} formItem={{ editorOptions: { showClearButton: true } }} />
              <Column caption={"R"} dataField={"r"} dataType={"number"} width={100} formItem={{ editorOptions: { showClearButton: true } }} />
              <Column
                caption={"Standby"}
                dataField={"standby"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column
                caption={"Last Year"}
                dataField={"lastYear"}
                dataType={"number"}
                width={100}
                formItem={{ editorOptions: { showClearButton: true } }}
              />
              <Column caption={"Override Activity Cost"} dataField={"overrideActivityCost"} dataType={"boolean"} width={100} />
              <Column
                caption={"Notes"}
                dataField={"notes"}
                dataType={"string"}
                width={400}
                allowEditing={false}
                formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
              />
            </Grid>
          </div>
        </ProgressPanel>
      );
    },
    ["selectedRowsData", "filter"]
  )
);
