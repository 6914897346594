import React, { useState, useRef } from "react";
import styles from "./styles.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { url } from "config.js";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { Column, Lookup } from "devextreme-react/data-grid";
import { addFilter, withMemo } from "helper/global";
import StrategyCondition from "../strategycondition"

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  return (
    <div className={styles.main}>
      <div className={styles.strategy}>
        <Strategy strategyGroupId={props.strategyGroupId} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.container}>{<StrategyCondition strategyGroupId={props.strategyGroupId} strategyId={state.id} />}</div>
    </div>
  );
}

export default Component;

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const Strategy = connect(
  null,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      // stores

      const strategyStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            if (!props.strategyGroupId) return Promise.resolve({ data: [] });
             return request({ url: `${url}/strategy/strategygroup/${props.strategyGroupId}`, loadOptions: loadOptions });
          },
         
        }),
      };

      const strategyGroupStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/strategygroup`, loadOptions: addFilter(loadOptions, "and", ["internal", "=", false]) });
          },
          byKey: function (id) {
            return request({ url: `${url}/strategygroup/${id}` });
          },
        }),
      };

      // event handlers

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_STRATEGY", url: `${url}/strategy/upload/file/${fileId}` });
      }

      return (
        <ProgressPanel name={"UPLOAD_STRATEGY"}>
          <Grid
            title={"Strategy"}
            dataSource={strategyStore}
            selection={{ mode: "single" }}
            onSelectionChanged={props.onSelectionChanged}
            allowExpanding={true}
            allowAdding = {false}
            allowUpdating = {false}
            allowDeleting = {false}
            allowDownloading = {false}
            
          >
            <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
            <Column caption={"Operation"} dataField={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
            <Column caption={"Name"} dataField={"name"} width={200} />
            <Column caption={"Description"} dataField={"description"} width={400} />
            <Column caption={"Group"} dataField={"groupId"} visible={false} calculateDisplayValue="group" allowFiltering={true} width={150}>
              <Lookup dataSource={strategyGroupStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
            </Column>
            
            <Column
              caption={"Potential Useful Life"}
              dataField={"potentialUsefulLife"}
              dataType={"number"}
              width={100}
              formItem={{ editorOptions: { showClearButton: true } }}
            />
            <Column
              caption={"Shape Factor"}
              dataField={"shapeFactor"}
              dataType={"number"}
              width={100}
              formItem={{ editorOptions: { showClearButton: true } }}
            />
            <Column visible={false} formItem={{ itemType: "empty" }} />
            <Column
              caption={"Notes"}
              dataField={"notes"}
              allowFiltering={true}
              formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
            />
          </Grid>
        </ProgressPanel>
      );
    },
    ["strategyGroupId"]
  )
);
