import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "helper/global";

const initialState = {
  isAuthenticated: null,
  loading: false,
};

const authStart = (state) => {
  return updateObject(state, { loading: true });
};

const authEnd = (state, action) => {
  return updateObject(state, {
    isAuthenticated: action.payload,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_END:
      return authEnd(state, action);
    default:
      return state;
  }
};

export default reducer;
