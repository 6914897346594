import React, { useState } from "react";
import styles from "./log.module.scss";
import { withMemo, notify } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import { confirm } from "devextreme/ui/dialog";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import JobPanel from "components/jobpanel/jobpanel";

function Component(props) {
  //
  // store

  const datasetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/kon/sta/dataset/${state.id}`,
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // hooks
  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  async function onRun() {
    var result = await confirm("<i>Do you really want to run the selected job?</i>", "Confirm");
    if (result) {
      try {
        props.run({ name: "RUN", url: `${url}/kon/run/job/1` });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  return (
    <JobPanel name={"RUN"}>
      <div className={styles.main}>
        <div className={styles.log}>
          <LogGrid onRun={onRun} onSelectionChanged={onSelectionChanged} />
        </div>
        <div className={styles.dataset}>
          {state.id && (
            <Grid
              title={"Dataset"}
              dataSource={datasetStore}
              selection={{ mode: "single" }}
              allowExpanding={true}
              showButtons={false}
              allowAdding={false}
              allowUpdating={false}
              allowDeleting={false}
              allowDownloading={false}
            >
              <Column caption={"Id"} visible={false} dataField={"id"} width={100} />
              <Column caption={"Section Code"} dataField={"sectionCode"} width={200} />
              <Column caption={"Dataset Id"} dataField={"datasetId"} width={300} />
              <Column caption={"Created At"} dataField={"createdAt"} width={200} />
              <Column caption={"Version"} dataField={"version"} width={100} />
              <Column caption={"Project Id"} dataField={"projectId"} width={75} />
              <Column caption={"Project Type"} dataField={"projectType"} width={150} />
              <Column caption={"Inspection Order Number"} dataField={"inspectionOrderNumber"} width={125} />
            </Grid>
          )}
        </div>
      </div>
    </JobPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.startJob(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const LogGrid = withMemo((props) => {
  //
  // stores
  const assetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/kon/log/1`,
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",
      visible: true, //allowAdding,
      options: {
        hint: "Run",
        icon: "fas fa-play",
        onClick: props.onRun,
      },
    });

    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return (
    <Grid
      title={"Log"}
      dataSource={assetStore}
      showButtons={false}
      selection={{ mode: "single" }}
      resetRowSelectionOnLoad={true}
      onSelectionChanged={props.onSelectionChanged}
      allowExpanding={true}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onToolbarPreparing={onToolbarPreparing}
    >
      <Column caption={"LogId"} dataField={"id"} width={100} sortOrder={"desc"} />
      <Column caption={"Job"} dataField={"job"} width={200} />
      <Column caption={"DateTime"} dataField={"dateTime"} dataType={"date"} format={"yyyy-MM-dd HH:mm:ss"} width={200} />
      <Column caption={"Status"} dataField={"status"} width={200} />
      <Column caption={"Message"} dataField={"message"} />
    </Grid>
  );
}, []);
