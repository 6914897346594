import { memo, useRef, useState } from "react";
import dxNotify from "devextreme/ui/notify";
import { faFile, faFilePdf, faFileWord, faFileExcel, faFileCsv } from "@fortawesome/pro-duotone-svg-icons";
import { store } from "index.js";
import { formatMessage } from "devextreme/localization";

export function selectedTabItem(tabItems, path, level) {
  let tabPath = "/" + path.split("/")[level];
  let pathExist = false;
  if (path && tabItems.some((item) => item.path === tabPath)) pathExist = true;
  pathExist || (tabPath = tabItems[0].path);
  return tabPath;
}

export function addFilter(loadOption, operator, filter) {
  if (loadOption.filter) loadOption.filter.push(operator, filter);
  else loadOption.filter = filter;

  return loadOption;
}

export function currencyFormat(num) {
  let symbol = store.getState().org.currency || "$";
  return symbol + commaFormat(num);
}

export function currency() {
  return store.getState().org.currency || "$";
}

export function commaFormat(num) {
  return !!num ? num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "0";
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function notify(message, type) {
  dxNotify(
    {
      message: message,
      position: {
        my: "middle center",
        at: "middle center",
      },
      width: 0,
      shading: true,
    },
    type,
    1000
  );
}

export function getIcon(filename) {
  switch (filename.split(".").pop()) {
    case "pdf":
      return faFilePdf;
    case "doc":
    case "docx":
      return faFileWord;
    case "xls":
    case "xlsx":
      return faFileExcel;
    case "csv":
      return faFileCsv;
    default:
      return faFile;
  }
}

// export async function loadHomeScreen() {
//   let p1 = httpRequest(`${url}/analyze/conditiondistribution?type=3`);
//   let p2 = httpRequest(`${url}/analyze/riskratingdistribution`);
//   let p3 = httpRequest(`${url}/analyze/consumptiondistribution`);
//   let p4 = httpRequest(`${url}/analyze/costprofile/?activity=All&escalate=false&smoothing=0`);
//   let [r1, r2, r3, r4] = await Promise.all([p1, p2, p3, p4]);
//   let data = {
//     condition: r1,
//     risk: r2,
//     consumption: r3,
//     cost: r4.profile,
//     header: { ...r4.header, highRiskCost: r2.find((i) => i.rating === "High").cost },
//   };
//   localStorage.setItem("homescreen", data);
//   return data;
// }

export function now() {
  var result = "";
  var d = new Date();
  result += d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear() + " " + d.getHours() + "h " + d.getMinutes() + "m";
  console.log("DATETIME", result);
  return result;
}

export function dict(name, en) {
  return formatMessage(`_${name}`) || name;
}

export function withMemo(Component, checkedProps) {
  function areEqual(prevProps, nextProps) {
    let isEqual = true;
    for (let i = 0; i < checkedProps.length; i++) {
      const checkedProp = checkedProps[i];
      if (JSON.stringify(prevProps[checkedProp]) !== JSON.stringify(nextProps[checkedProp])) {
        isEqual = false;
        break;
      }
    }
    return isEqual;
  }

  return memo(Component, areEqual);
}

export function isObjectNullOrEmpty(obj) {
  return obj && Object.keys(obj).length > 0 && obj.constructor === Object;
}

export function toMillion(labelValue, decimal = 2) {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? parseFloat((Math.abs(Number(labelValue)) / 1.0e9).toFixed(decimal)) + "B"
    : Math.abs(Number(labelValue)) >= 1.0e6
    ? parseFloat((Math.abs(Number(labelValue)) / 1.0e6).toFixed(decimal)) + "M"
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? parseFloat((Math.abs(Number(labelValue)) / 1.0e3).toFixed(decimal)) + "K"
    : parseFloat(Math.abs(Number(labelValue)).toFixed(decimal));
}

export function colorLuminance(hex, lum) {
  // validate hex string
  if (!hex) return "#fff";
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

export function useRefState(value) {
  const ref = useRef(value);
  const [, forceRender] = useState(false);

  function setState(newState) {
    ref.current = newState;
    forceRender((s) => !s);
  }
  return [ref, setState];
}

export function logError(error) {
  console.log("%cError: ", "color:red", error);
}
