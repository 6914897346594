import React from "react";
import { withMemo } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";

function Component(props) {
  //
  // store

  const datasetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.logId) return Promise.resolve({ data: [] });
        return request({
          url: `${url}/kon/pmp/luietrap/updated/job/${props.logId}`,
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // functions

  return (
    <Grid
      title={"Dataset"}
      dataSource={datasetStore}
      allowExpanding={true}
      showButtons={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onCellPrepared={props.onCellPrepared}
    >
      <Column caption={"Id"} visible={false} dataField={"id"} />
      <Column caption={"Name"} dataField={"name"} width={300} />
      <Column caption={"Description"} dataField={"description"} width={300} />
      <Column caption={"Type"} dataField={"type"} width={300} />
      <Column caption={"Size"} dataField={"size"} width={300} />
      <Column caption={"Material"} dataField={"material"} width={200} />
      <Column caption={"StatusId"} dataField={"statusId"} width={150}>
        <Lookup dataSource={props.statusStore} valueExpr={"id"} displayExpr="name" />
      </Column>
      <Column caption={"Notes"} dataField={"notes"} width={400} />
    </Grid>
  );
}

export default withMemo(Component, ["logId"]);
