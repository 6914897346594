export const AUTH_START = "AUTH_START";
export const AUTH_END = "AUTH_END";

export const ORG_LOAD = "ORG_LOAD";

export const USER_LOAD = "USER_LOAD";

export const PROGRESS_START = "PROGRESS_START";
export const PROGRESS_CANCEL = "PROGRESS_CANCEL";
export const PROGRESS_END = "PROGRESS_END";

export const TOGGLE_MENU = "TOGGLE_MENU";

export const SCENARIO_LOAD = "SCENARIO_LOAD";

export const JOB_START = "JOB_START";
export const JOB_END = "JOB_END";
export const JOB_PROGRESS = "JOB_PROGRESS";
