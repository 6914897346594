import React, { useRef } from "react";
import styles from "./dataset.module.scss";
import { addFilter, withMemo } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule, RangeRule, NumericRule } from "devextreme-react/validator";
import { confirm, alert } from "devextreme/ui/dialog";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  //
  // stores

  const dataSetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.jobId) return Promise.resolve([]);
        return request({
          url: `${url}/kon/pmp/${props.name}/new/job/${props.jobId}`,
          loadOptions: loadOptions,
        });
      },
      update: (_, data) => {
        return request({ url: `${url}/kon/pmp/${props.name}`, method: "Put", data: data });
      },
      onModified: props.onRefresh,
    }),
  };

  const nodeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/hierarchy`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/hierarchy/${id}` });
      },
    }),
    paginate: true,
    pageSize: 20,
  };

  const nodeStatusStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/nodeStatus`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/nodeStatus/${id}` });
      },
    }),
  };

  const ownershipStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/ownership`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/ownership/${id}` });
      },
    }),
  };

  const classStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/class`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/class/${id}` });
      },
    }),
  };

  const typeStore = (options) => {
    return {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOption) => {
          let options_ = options.data ? addFilter(loadOption, "and", ["classId", "=", options.data.classId]) : loadOption;
          return request({ url: `${url}/type`, loadOptions: options_ });
        },
        byKey: function (id) {
          return request({ url: `${url}/type/${id}` });
        },
      }),
    };
  };

  const sizeStore = (options) => {
    return {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOption) => {
          let options_ = options.data ? addFilter(loadOption, "and", ["classId", "=", options.data.classId]) : loadOption;
          return request({ url: `${url}/size`, loadOptions: options_ });
        },
        byKey: function (id) {
          return request({ url: `${url}/size/${id}` });
        },
      }),
    };
  };

  const materialStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/material`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/material/${id}` });
      },
    }),
  };

  const manufacturerStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/manufacturer`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/manufacturer/${id}` });
      },
    }),
  };

  const strategyStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/strategy`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/strategy/${id}` });
      },
    }),
    paginate: true,
    pageSize: 20,
  };

  const costStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cost`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cost/${id}` });
      },
    }),
    paginate: true,
    pageSize: 20,
  };

  let nodeTypes = [
    { id: 0, name: "Asset" },
    { id: 2, name: "SuperAsset" },
  ];

  // hooks

  const dataGrid = useRef(null);

  // event handlers

  function onInitialized(e) {
    dataGrid.current = e.component;
    props.onInitialized && props.onInitialized(e);
  }

  async function ignore(e) {
    var result = await confirm("<i>Do you really want to ignore the selected dataset?</i>", "Confirm");
    if (result) {
      try {
        await request({ url: `${url}/kon/pmp/${props.name}/ignore`, method: "Put", data: e.row.data });
        dataGrid.current.refresh();
      } catch (e) {
        alert(`<div style="color:red;font-weight: bold;" >${e.message}</div>`, "Error");
      }
    }
  }

  function setCellValue(newData, value, oldData) {
    newData.classId = value;
    newData.typeId = null;
    newData.sizeId = null;
  }

  // render

  return (
    <div className={styles.main}>
      <Grid
        title={"Asset"}
        dataSource={dataSetStore}
        showButtons={false}
        onInitialized={onInitialized}
        resetRowSelectionOnLoad={true}
        allowExpanding={true}
        allowAdding={false}
        allowDownloading={false}
        onContentReady={props.onContentReady}
        onSelectedRowKeysChange={props.onSelectedRowKeysChange}
        onOptionChanged={props.onOptionChanged}
        // onToolbarPreparing={onToolbarPreparing}
      >
        <Column caption={"Id"} dataField={"id"} visible={false} width={100} formItem={{ visible: false }} />
        <Column caption={"AssetId"} dataField={"name"} width={200}>
          <RequiredRule />
        </Column>
        <Column caption={"Description"} dataField={"description"} dataType={"string"} width={400}>
          <RequiredRule />
        </Column>
        <Column caption={"Parent"} dataField={"parentId"} calculateDisplayValue="parent" width={200}>
          <Lookup dataSource={nodeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Node Type"} dataField={"nodeTypeId"} width={100} visible={false} allowSorting={false}>
          <Lookup dataSource={nodeTypes} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Location"} dataField={"location"} dataType={"string"} width={200} />
        <Column caption={"Ownership"} dataField={"ownershipId"} calculateDisplayValue="ownership" width={200} visible={true}>
          <Lookup dataSource={ownershipStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
        </Column>
        <Column caption={"Status"} dataField={"statusId"} calculateDisplayValue="status" width={200}>
          <Lookup dataSource={nodeStatusStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
        </Column>
        <Column caption={"Class"} dataField={"classId"} calculateDisplayValue="class" width={200} dataType="number" setCellValue={setCellValue}>
          <Lookup dataSource={classStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={200}>
          <Lookup dataSource={typeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Size"} dataField={"sizeId"} calculateDisplayValue="size" width={200}>
          <Lookup dataSource={sizeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Material"} dataField={"materialId"} calculateDisplayValue="material" width={200}>
          <Lookup dataSource={materialStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Manufacturer"} dataField={"manufacturerId"} calculateDisplayValue="manufacturer" width={200}>
          <Lookup dataSource={manufacturerStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Model"} dataField={"model"} width={200} />
        <Column
          caption={"Install Year"}
          dataField={"installYear"}
          dataType={"number"}
          width={100}
          formItem={{ visible: true, editorOptions: { readOnly: false } }}
        >
          <NumericRule />
        </Column>
        <Column caption={"Count"} dataField={"count"} dataType={"number"} width={100}>
          <NumericRule />
          <RangeRule min={1} />
        </Column>
        <Column caption={"Strategy"} dataField={"strategyId"} calculateDisplayValue="strategy" width={200}>
          <Lookup dataSource={strategyStore} valueExpr={"id"} displayExpr="name" />
        </Column>
        <Column caption={"Cost"} dataField={"costId"} calculateDisplayValue="cost" width={200}>
          <Lookup dataSource={costStore} valueExpr={"id"} displayExpr="name" />
        </Column>
        <Column caption={"UsefulLife"} dataField={"usefulLife"} dataType={"number"} width={200} />
        <Column caption={"Cof"} dataField={"cof"} dataType={"number"} width={200} />
        <Column caption={"R"} dataField={"r"} dataType={"number"} width={200} />
        <Column caption={"Standby"} dataField={"standby"} dataType={"number"} width={200} />
        <Column caption={"CostOverride"} dataField={"costOverride"} dataType={"number"} width={200} />
        {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
        <Column
          caption={"Notes"}
          dataField={"notes"}
          dataType={"string"}
          width={400}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
        <Column
          type={"buttons"}
          buttons={[
            {
              hint: "Resolve",
              icon: "check",
              onClick: (e) => {
                dataGrid.current.editRow(e.row.rowIndex);
              },
            },
            {
              hint: "Ignore",
              icon: "close",
              onClick: ignore,
            },
          ]}
          width={100}
          fixed={true}
        />
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["jobId"]));
