import React from "react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import styles from "./indicator.module.scss";
import logo from "./logo.png";
import animation from "./animation.gif";

function Component() {
  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <img src={logo} width="75" height="48" alt="" />
      </div>
      <div className={styles.gif}>
        <LoadIndicator indicatorSrc={animation} height={15} width={120} />
      </div>
    </div>
  );
}

export default Component;
