import React, { useEffect } from "react";
import Layout from "layout/layout";
import { Switch, Route, withRouter, useLocation, useHistory, Redirect } from "react-router-dom";
import { navigation } from "./navigation";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { url } from "config.js";
import { withMemo } from "helper/global";
import Sta from "./sta/sta";
import Pmp from "./pmp/pmp";
import Condition from "./cond/cond";
import Admin from "./admin/admin";

function Component(props) {
  //
  // hooks

  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    let jwt = query.get("jwt");
    props.authenticate(`${url}/kon`, jwt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      if (query.has("jwt")) {
        query.delete("jwt");
        history.replace({
          search: query.toString(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAuthenticated]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  return (
    props.isAuthenticated && (
      <Switch>
        <Route
          path="/klient"
          render={() => {
            return <MemorizedLayout isAdmin={props.isAdmin} />;
          }}
        />
        <Redirect to="/klient" />
      </Switch>
    )
  );
}

const MemorizedLayout = withMemo((props) => {
  //
  // functions

  function setNavigation() {
    const nav = JSON.parse(JSON.stringify(navigation));
    const ada = nav.find((i) => i.text === "Connect");
    let admin = ada.items.find((i) => i.text === "Admin");
    admin.visible = props.isAdmin;
    return nav;
  }

  // render

  return (
    <Layout navigation={setNavigation()}>
      <Switch>
        <Route exact path={"/klient/kon"} component={Sta} />
        <Route path={"/klient/kon/sta"} component={Sta} />
        <Route path={"/klient/kon/pmp"} component={Pmp} />
        <Route path={"/klient/kon/cond"} component={Condition} />
        <Route path={"/klient/kon/admin"} component={Admin} />
      </Switch>
    </Layout>
  );
}, []);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.user.roleId === 1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (url, jwt) => dispatch(actions.authenticate(url, jwt)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
