import React, { useState } from "react";
import styles from "./dataset.module.scss";
import { withMemo, currency } from "helper/global";
import Grid from "components/data-grid/data-grid";
import { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";

function Component() {
  //
  // stores

  const interventionStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/kon/sta/intervention/${state.id}`,
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // evnet handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.dataset}>
        <Dataset onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.intervention}>
        {state.id && (
          <Grid
            title={"Intervention"}
            dataSource={interventionStore}
            allowExpanding={true}
            showButtons={false}
            allowAdding={false}
            allowUpdating={false}
            allowDeleting={false}
            allowDownloading={false}
          >
            <Column caption={"Id"} visible={false} dataField={"id"} width={100} />
            <Column caption={"Year"} dataField={"year"} width={200} />
            <Column caption={"Cost"} dataField={"cost"} width={300} dataType={"number"} format={`${currency()}#,##0.##`} />
            <Column caption={"Type"} dataField={"type"} width={200} />
          </Grid>
        )}
      </div>
    </div>
  );
}

export default Component;

const Dataset = withMemo((props) => {
  //
  // hooks

  // stores

  const datasetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/kon/sta/dataset`,
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Dataset"}
      dataSource={datasetStore}
      selection={{ mode: "single" }}
      onSelectionChanged={props.onSelectionChanged}
      allowExpanding={true}
      showButtons={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
    >
      <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
      <Column caption={"Dataset Id"} dataField={"datasetId"} width={300} />
      <Column caption={"Created At"} dataField={"createdAt"} width={200} />
      <Column caption={"Version"} dataField={"version"} width={100} />
      <Column caption={"Project Id"} dataField={"projectId"} width={100} />
      <Column caption={"Project Type"} dataField={"projectType"} width={150} />
      <Column caption={"Inspection Order Number"} dataField={"inspectionOrderNumber"} width={125} />
      <Column caption={"AssetId"} dataField={"name"} width={200} />
      <Column caption={"Description"} dataField={"description"} width={400} />
      <Column caption={"Class"} dataField={"class"} width={200} />
      <Column caption={"LogId"} dataField={"logId"} width={200} />
    </Grid>
  );
}, []);
