import React, { useEffect, useRef } from "react";
import { useImmerReducer } from "use-immer";
import TreeList from "./treelist/treelist";
import HierarchyContext from "./context";
import styles from "./hierarchy.module.scss";
import { connect } from "react-redux";
import { request } from "helper/http-client";
import { commaFormat, dict } from "helper/global";
import Filter from "components/filter/filter";
import { url } from "config.js";

// reducer

function reducer(draft, action) {
  switch (action.type) {
    case "field":
      draft[action.fieldName] = action.payload;
      return;
    case "selected":
      draft.selectedRowsData = action.payload;
      return;
    case "showFilterBuilder":
      draft.filterBuilder = true;
      return;
    case "hideFilterBuilder":
      draft.filterBuilder = false;
      return;
    default:
      return;
  }
}

// initial state

const initialState = {
  filterBuilder: false,
  count: 0,
  filterExpression: null,
  selectedRowsData: null,
};

function Component(props) {
  //
  // hooks

  const [state, disptach] = useImmerReducer(reducer, initialState);
  const treeList = useRef();
  const filterExpression = useRef(null);

  useEffect(() => {
    if (!state.selectedRowsData) return;
    if (props.countUrl) {
      count();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterExpression.current, state.selectedRowsData]);

  function count() {
    (async () => {
      //let filterUrl = props.showFilter ? `&filterExpression=${filterExpression.current}` : "";
      const options = {
        url: `${url}/node/count`,
        method: "Get",
        params: { filterExpression: props.showFilter ? filterExpression.current : null, ids: state.selectedRowsData.map((i) => i.id) },
      };
      let count = await request(options);
      disptach({ type: "field", fieldName: "count", payload: count });
    })();
  }

  // events handlers

  function onHiding() {
    disptach({ type: "hideFilterBuilder" });
  }

  function onFilterBuilder(e) {
    filterExpression.current = e;
    disptach({ type: "hideFilterBuilder" });
  }

  function onSelectionChanged(e) {
    disptach({ type: "selected", payload: e.selectedRowsData });
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  function onInitialized(e) {
    treeList.current = e.component;
  }

  function refresh(e) {
    switch (e) {
      case "count":
        count();
        break;
      case "hierarchy":
        treeList.current.refresh();
        break;
      default:
        break;
    }
  }

  //render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "after",
      widget: "dxButton",
      visible: !!props.showFilter,
      options: {
        hint: "Filter",
        icon: "filter",
        stylingMode: "contained",
        onClick: () => {
          disptach({ type: "showFilterBuilder" });
        },
      },
    });

    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return (
    <div className={styles.main}>
      {state.filterBuilder && (
        <Filter
          fields={props.filterFields || []}
          filterType={props.filterType}
          showFilter={state.filterBuilder}
          onHiding={onHiding}
          onFilterBuilder={onFilterBuilder}
        />
      )}
      <div className={styles.tree}>
        <div className={styles.row}>
          <div className={`${styles.col} theme-treelist`}>
            <TreeList
              render={state.render}
              allowEditing={props.allowEditing}
              allowDragging={props.allowDragging}
              multiSelect={props.multiSelect}
              onToolbarPreparing={onToolbarPreparing}
              onContextMenuPreparing={props.onContextMenuPreparing}
              onSelectionChanged={onSelectionChanged}
              onInitialized={onInitialized}
            />
          </div>
          {(props.showFilter || props.showCount) && (
            <div className={styles.footer}>
              <div className={styles.footer_filter}>{JSON.parse(filterExpression.current) === null ? "" : "Filter On"}</div>
              <div style={JSON.parse(filterExpression.current) && { color: "#bb86fc" }} className={styles.footer_count}>{`${dict(
                "Total Number of Assets"
              )}: ${commaFormat(state.count || 0)}`}</div>
            </div>
          )}
        </div>
      </div>
      {state.selectedRowsData && (
        <div className={styles.container}>
          <HierarchyContext.Provider
            value={{ data: state.selectedRowData, selectedRowsData: state.selectedRowsData, refresh: refresh, filter: filterExpression.current }}
          >
            {props.children}
          </HierarchyContext.Provider>
        </div>
      )}
    </div>
  );
}



export default Component;
