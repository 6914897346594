import * as actionTypes from "./actionTypes";
import { requestBlob, getQueryParams } from "helper/http-client";
import { notify } from "helper/global";
import { create_UUID } from "helper/global";

export const progress = (options) => {
  const { name, url, params } = options;
  return async (dispatch, getState) => {
    //
    //
    if (getState().progress.name) {
      notify("Another operation is already in progress", "error");
      return;
    }
    const id = create_UUID();

    let p = "";

    if (params) p = getQueryParams(params);

    const [promise, onCancel] = requestBlob({ url: `${url}/${id}?${p}` });

    dispatch({
      type: actionTypes.PROGRESS_START,
      name: name,
      id: id,
      onCancel: () => {
        dispatch({ type: actionTypes.PROGRESS_CANCEL });
        onCancel();
      },
    });
    try {
      await promise;
    } catch (error) {
      notify("Operation Unsuccessful", "error");
    } finally {
      dispatch({ type: actionTypes.PROGRESS_END });
    }

    return "done";
  };
};
