export const navigation = [
  {
    text: "Connect",
    icon: "fad fa-link",
    items: [
      {
        text: "STADIUM",
        path: "/kon/sta",
        icon: "fad fa-cloud-download",
      },
      {
        text: "PortMaps",
        path: "/kon/pmp",
        icon: "fad fa-cloud-download",
      },
      {
        text: "Condition",
        path: "/kon/cond",
        icon: "fad fa-cloud-download",
      },
      {
        text: "Admin",
        path: "/kon/admin",
        icon: "fad fa-users-crown",
      },
    ],
  },
];
