import React, { useRef } from "react";
import styles from "./styles.module.scss";
import {DataGrid1 as Grid} from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { withMemo } from "helper/global";

function Component(props) {
  // stores

  const activityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.strategyId) return Promise.resolve({ data: [] });
        return exporting.current
          ? request({
              url: `${url}/coa/strategyconditionrating/strategygroup/${props.strategyGroupId}/download`,
              loadOptions: loadOptions,
            })
          : request({
              url: `${url}/coa/strategyconditionrating/strategy/${props.strategyId}`,
              loadOptions: loadOptions
            });
      },
      insert: (data) => {
        data.strategyId = props.strategyId;
        return request({ url: `${url}/coa/strategyconditionrating`, method: "Post", data: data });
      },
      update: (_, data) => {
        data.strategyId = props.strategyId;
        return request({ url: `${url}/coa/strategyconditionrating`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/coa/strategyconditionrating/${id}`, method: "Delete" });
      },
    }),
  };

  const conditionRatingStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/conditionRating`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/conditionRating/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_STRATEGYCONDITION", url: `${url}/coa/strategyconditionrating/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("strategy", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("strategy", "visible", false);

    exporting.current = false;
  }

  // render

  return (
    <ProgressPanel name={"UPLOAD_STRATEGYCONDITION"}>
      <div className={styles.main}>
        <Grid
          title={"Condition Rating"}
          dataSource={activityStore}
          allowAdding = {false}
          allowDeleting = {false}
          // allowFiltering = {false}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
          allowExpanding={true}
        >
          <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            width={100}
            visible={false}
            formItem={{ visible: false }}
          />
          <Column caption={"Strategy"} dataField={"strategy"} allowEditing = {false} width={200} visible = {false } formItem={{ visible: false }}/>
          <Column caption={"Condition Rating"} dataField={"conditionRating"} allowFiltering = {false} allowEditing = {false}  width={200}/>
          <Column caption={"Remaining Useful Life"} dataField={"remainingUsefulLife"} allowFiltering = {false} width={200} formItem={{ editorOptions: { readOnly: false, showClearButton: true } }}   />
          
        </Grid>
      </div>
    </ProgressPanel>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["strategyId", "strategyGroupId"]));
