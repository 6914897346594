import React, { useEffect, useRef } from "react";
import Progress from "../progress/progress";
import { connect } from "react-redux";
import styles from "./progress-panel.module.scss";
import HierarchyContext from "./context";

function Component(props) {
  //
  // render

  // useRef
  const processing = useRef(false);
  const state = useRef(null);

  // useEffect
  useEffect(() => {
    if (!processing.current && props.progressName === props.name) {
      processing.current = true;
      return;
    }

    if (processing.current && !props.processId_) {
      props.onComplete && props.onComplete();
      processing.current = false;
    }
  });

  // functions

  function onStateChanged(e) {
    state.current = e;
  }

  return props.name === props.progressName ? (
    <div className={styles.main}>
      <Progress progressId={props.progressId} onCancel={props.progressOnCancel} />
    </div>
  ) : (
    <HierarchyContext.Provider value={{ state: state.current, onStateChanged: onStateChanged }}>
      <div className={styles.main}>{props.children}</div>
    </HierarchyContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return {
    progressName: state.progress.name,
    progressId: state.progress.id,
    progressOnCancel: state.progress.onCancel,
  };
};

export default connect(mapStateToProps, null)(Component);
