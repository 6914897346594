import React from "react";
import sytles from "./admin.module.scss";
import User from "./user/user";

function Component() {
  return (
    <div className={sytles.main}>
      <User />
    </div>
  );
}

export default Component;
