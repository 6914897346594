import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "helper/global";

const initialState = {
  roleId: 0,
  name: null,
  avatarUrl: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOAD:
      return updateObject(state, {
        ...action,
      });
    default:
      return state;
  }
};

export default reducer;
