import React, { useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import styles from "./cofclass.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions";

function Component(props) {
  //
  // stores

  const classImapactFactorController = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/cof/class`,
          loadOptions: loadOptions,
        });
      },
      insert: (data) => {
        return request({ url: `${url}/cof/class`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/cof/class`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/cof/class/${id}`, method: "Delete" });
      },
    }),
  };

  const impactfactor = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cof/impactfactor`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cof/impactfactor/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_CORCLASS", url: `${url}/cof/class/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.beginUpdate();
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("maxCof", "visible", false);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("maxCof", "visible", true);
    e.component.endUpdate();
    exporting.current = false;
  }

  // render

  function typeRender() {
    return (
      <Grid
        title={"Max Cof"}
        showTitle={false}
        dataSource={classImapactFactorController}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} allowEditing={false} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          name={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          allowEditing={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} width={200} />
        <Column caption={"Description"} dataField={"description"} />
        <Column caption={"Financial"} dataField={"financialImpactFactorId"} calculateDisplayValue="financialImpactFactor" width={150}>
          <Lookup dataSource={impactfactor} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Image"} dataField={"imageImpactFactorId"} calculateDisplayValue="imageImpactFactor" width={150}>
          <Lookup dataSource={impactfactor} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Human Safety"} dataField={"humanSafetyImpactFactorId"} calculateDisplayValue="humanSafetyImpactFactor" width={150}>
          <Lookup dataSource={impactfactor} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Environmental"} dataField={"environmentalImpactFactorId"} calculateDisplayValue="environmentalImpactFactor" width={150}>
          <Lookup dataSource={impactfactor} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Core Business"} dataField={"coreBusinessImpactFactorId"} calculateDisplayValue="coreBusinessImpactFactor" width={150}>
          <Lookup dataSource={impactfactor} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Max Cof"} dataField={"maxCof"} dataType={"number"} width={150} formItem={{ visible: false }} />
        <Column visible={false} formItem={{ itemType: "empty" }} />

        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    );
  }

  return (
    <ProgressPanel name={"UPLOAD_CORCLASS"}>
      <div className={styles.main}>{typeRender()}</div>
    </ProgressPanel>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
