import React from "react";
import styles from "./new.module.scss";
import Dataset from "./dataset/dataset";

function Component(props) {
  //
  // hooks

  function getName() {
    switch (props.jobId) {
      case 2:
        return "luietrap";
      default:
        return "luietrap";
    }
  }

  // render

  return (
    <div className={styles.main}>
      <Dataset jobId={props.jobId} name={getName()} />
    </div>
  );
}

export default Component;
