export const navigation = [
  {
    text: "Cof Calculator",
    icon: "fad fa-calculator",
    items: [
      {
        text: "Lookup",
        path: "/cof/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Input",
        path: "/cof/input",
        icon: "fad fa-keyboard",
      },
      {
        text: "Admin",
        path: "/cof/admin",
        icon: "fad fa-users-crown",
      },
    ],
  },
];
