import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { notify } from "helper/global";
import { loadUser } from "./user";
import { loadOrg } from "./org";

const _authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

const _authEnd = (auth) => {
  return {
    type: actionTypes.AUTH_END,
    payload: auth,
  };
};

// public functions

export const authenticate = (url, jwt) => {
  return async (dispatch) => {
    dispatch(_authStart());
    jwt = jwt || sessionStorage.getItem("jwt") || "";
    if (jwt) sessionStorage.setItem("jwt", jwt);
    var auth = false;
    try {
      auth = await request({ url: `${url}/auth/${jwt}`, method: "Post" });
      if (auth) {
        await Promise.all([dispatch(loadUser(url)), dispatch(loadOrg())]);
      }
    } catch {
      notify("Access denied", "error");
    } finally {
      dispatch(_authEnd(auth));
    }
    return "done";
  };
};
