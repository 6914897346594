import React, { useState } from "react";
import styles from "./styles.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { DataGrid2 as List } from "@kayugasolution/ui";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import Strategy from "./strategy";

function Component() {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.group}>
        <StrategyGroup onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.strategy}>{state && <Strategy strategyGroupId={state.id} />}</div>
    </div>
  );
}

const StrategyGroup = React.memo(
  (props) => {
    const strategyGroupStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `${url}/strategygroup`, loadOptions: loadOptions });
        },
        
      }),
    };

    function onSelectionChanged(e) {
      props.onSelectionChanged && props.onSelectionChanged(e);
    }

    return (
      <List title={"Category"} allowAdding = {false} allowUpdating = {false}  allowDeleting = {false} allowDownloading={false} dataSource={strategyGroupStore} onSelectionChanged={onSelectionChanged}>
        <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} sortOrder={"asc"} sortIndex={1} />
      </List>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
  () => {
    return true;
  }
);

export default Component;
