import React, { useState } from "react";
import styles from "./pmp.module.scss";
import { Route, Switch } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import { selectedTabItem } from "helper/global";
import { dict, notify, useRefState } from "helper/global";
import New from "./new/new";
import Updated from "./updated/updated";
import Job from "../common/job/job";
import { url } from "config.js";
import { confirm } from "devextreme/ui/dialog";
import * as actions from "redux/actions";
import { connect } from "react-redux";
import JobPanel from "components/jobpanel/jobpanel";

function Component(props) {
  //
  // hooks
  const initialState = { id: null };
  const [state, setState] = useRefState(initialState);

  // event handlers

  function changeView(e) {
    props.history.push(`/klient/kon/pmp${e.path}`);
  }

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  async function onRun() {
    var result = await confirm("<i>Do you really want to run the selected job?</i>", "Confirm");
    if (result) {
      try {
        props.run({ name: "RUN", url: `${url}/kon/run/job/${state.current.id}` });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  // render

  const tabItems = [
    {
      text: dict("New"),
      icon: "fad fa-database",
      path: "/new",
    },
    {
      text: dict("Updated"),
      icon: "fad fa-database",
      path: "/updated",
    },
  ];

  function tabRender() {
    return <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView}></Tabs>;
  }

  // render

  function newDatasetRender() {
    return <New jobId={state.current.id} />;
  }

  function updatedDatasetRender() {
    return <Updated jobId={state.current.id} />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>{tabRender()}</div>
      <div className={styles.container1}>
        <div className={styles.job}>
          <Job typeId={1} onRun={onRun} onSelectionChanged={onSelectionChanged} />
        </div>
        <div className={styles.container2}>
          <JobPanel name={"RUN"}>
            <Switch>
              <Route exact path={["/klient/kon", "/klient/kon/pmp"]} render={newDatasetRender} />
              <Route exact path="/klient/kon/pmp/new" render={newDatasetRender} />
              <Route exact path="/klient/kon/pmp/updated" render={updatedDatasetRender} />
            </Switch>
          </JobPanel>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.startJob(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
