import React, { useRef, useEffect } from "react";
import TreeList, { Column, RowDragging, Editing, Popup, Position, Lookup, FilterRow, StateStoring } from "devextreme-react/tree-list";
import { Template } from "devextreme-react/core/template";
import { RequiredRule } from "devextreme-react/validator";
import { connect } from "react-redux";
import { request } from "helper/http-client";
import { url } from "config.js";
import CustomStore from "devextreme/data/custom_store";
import { dict, withMemo } from "helper/global";

function Component(props) {
  function currency() {
    return "$";
  }

  //
  // stores

  const nodeStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/hierarchy`, loadOptions: loadOptions });
      },
    }),
  };

  const classStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/class`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/class/${id}` });
      },
    }),
  };

  // hooks

  const treeList = useRef();
  const rendered = useRef(false);

  useEffect(() => {
    treeList.current.instance.option("selection.mode", "single");
    treeList.current.instance.option("rowDragging.allowDropInsideItem", false);
  }, []);

  useEffect(() => {
    rendered.current = true;
  });

  // events handlers

  function toggleEditing() {
    let count = treeList.current.instance.columnCount();
    let optionValue = !treeList.current.instance.columnOption(count - 1, "visible");
    treeList.current.instance.columnOption(count - 1, "visible", optionValue);
  }

  function toggleDragging() {
    let optionValue = !treeList.current.instance.option("rowDragging.allowDropInsideItem");
    treeList.current.instance.option("rowDragging.allowDropInsideItem", optionValue);
  }

  async function toggleSelectionMode() {
    let selectedRowKeys = treeList.current.instance.option("selectedRowKeys");
    if (selectedRowKeys) treeList.current.instance.selectRows(selectedRowKeys, false);
    else treeList.current.instance.selectRowsByIndexes([0]);
    let optionValue = treeList.current.instance.option("selection.mode");
    optionValue = optionValue === "single" ? "multiple" : "single";
    treeList.current.instance.option("selection.mode", optionValue);
  }

  rendered.current = false;
  treeList.current && treeList.current.instance.clearSelection();

  function onContentReady(e) {
    var selectionMode = e.component.option("selection").mode;
    let selectedRowKeys = e.component.option("selectedRowKeys");
    if (selectionMode === "single" && selectedRowKeys.length === 0 && e.component.totalCount() > 0) {
      e.component.selectRowsByIndexes([0]);
      e.component.forEachNode((node) => {
        if (node.level < 1) {
          e.component.expandRow(node.key);
        }
      });
    }
  }

  function onKeyDown(e) {
    var selKey = e.component.getSelectedRowKeys();
    if (selKey.length) {
      var currentKey = selKey[0];
      var index = e.component.getRowIndexByKey(currentKey);
      if (e.event.keyCode === 38) {
        index--;
        if (index >= 0) {
          e.component.selectRowsByIndexes([index]);
          e.event.stopPropagation();
        }
      } else if (e.event.keyCode === 40) {
        index++;
        e.component.selectRowsByIndexes([index]);
        e.event.stopPropagation();
      }
    }
  }

  function onDragEnd(e) {
    let visibleRows = e.component.getVisibleRows(),
      sourceNode = e.component.getNodeByKey(e.itemData.id),
      targetNode = visibleRows[e.toIndex].node;
    if (sourceNode.data.id !== targetNode.data.id) {
      props.onParentChanged && props.onParentChanged(sourceNode.data.id, targetNode.data.id);
    }
  }

  function onAddRow() {
    let selectedRowKeys = treeList.current.instance.option("selectedRowKeys");
    if (selectedRowKeys.length === 0) {
      console.log("Hierarchy selection error");
      return;
    }
    treeList.current.instance.addRow(selectedRowKeys[0]);
  }

  function onRowInserted(e) {
    e.component.selectRows([e.key], false);
  }

  function onRowUpdating(e) {
    e.newData = { ...e.oldData, ...e.newData };
  }

  function onRowRemoving(e) {
    treeList.current.instance.clearSelection();
  }

  function onRowRemoved() {
    treeList.current.instance.selectRowsByIndexes([0]);
  }

  function onInitNewRow(e) {
    e.data.nodeType = "Folder";
    e.data.nodeTypeEnum = 1;
  }

  function customSave(e) {
    sessionStorage.setItem("hierarchy", JSON.stringify(e));
  }

  function customLoad() {
    var state = sessionStorage.getItem("hierarchy");
    if (state) {
      state = JSON.parse(state);
      return state;
    }
  }

  //render

  function titleRender() {
    return (
      <div className={`theme-grid-title`}>
        <div>{dict("Hierarchy")}</div>
      </div>
    );
  }

  function onContextMenuPreparing(e) {
    if (!e.items) e.items = [];

    e.items.push(
      {
        text: "Allow Dragging",
        selected: treeList.current.instance.option("rowDragging.allowDropInsideItem"),
        icon: "fas fa-bars",
        visible: props.allowDragging,
        onItemClick: toggleDragging,
      },
      {
        text: "Allow Editing",
        selected: treeList.current.instance.columnOption(5, "visible"),
        icon: "fas fa-edit",
        visible: props.allowEditing,
        onItemClick: toggleEditing,
        beginGroup: true,
      },
      {
        text: "Allow Multi-Select",
        selected: treeList.current.instance.option("selection.mode") === "multiple",
        icon: "fas fa-check-square",
        visible: props.multiSelect,
        onItemClick: toggleSelectionMode,
        beginGroup: true,
      }
    );

    props.onContextMenuPreparing && props.onContextMenuPreparing(e);
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      template: "titleRender",
    });

    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",
      visible: props.allowEditing,
      options: {
        hint: "Add a row",
        icon: "fas fa-plus",
        onClick: onAddRow,
      },
    });

    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return (
    <TreeList
      ref={treeList}
      dataSource={nodeStore}
      rootValue={null}
      selection={{ recursive: true }}
      showBorders={true}
      columnAutoWidth={true}
      showRowLines={false}
      keyExpr="id"
      parentIdExpr="parentId"
      hasItemsExpr="hasChildren"
      height={"100%"}
      showColumnHeaders={false}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="widget"
      onInitialized={props.onInitialized}
      onContentReady={onContentReady}
      onToolbarPreparing={onToolbarPreparing}
      onContextMenuPreparing={onContextMenuPreparing}
      onSelectionChanged={props.onSelectionChanged}
      onRowUpdating={onRowUpdating}
      onRowRemoving={onRowRemoving}
      onRowInserted={onRowInserted}
      onRowRemoved={onRowRemoved}
      onKeyDown={onKeyDown}
      onInitNewRow={onInitNewRow}
    >
      <StateStoring enabled={true} type="custom" customLoad={customLoad} customSave={customSave} />
      <RowDragging onDragEnd={onDragEnd} allowReordering={false} showDragIcons={true} />
      <Editing mode={"popup"} allowUpdating={true} allowDeleting={true}>
        <Popup title={dict("Hierarchy")} showTitle={true} width={700} height={700}>
          <Position my="center" at="center" of={window} />
        </Popup>
      </Editing>
      <FilterRow visible={true} />
      <Template name="titleRender" render={titleRender} />
      {props.rowNumbering ? (
        <Column
          key={"#"}
          caption={"#"}
          width={75}
          allowFiltering={true}
          allowEditing={true}
          cellRender={(e) => {
            return <div>{e.row.rowIndex + 1}</div>;
          }}
        />
      ) : null}
      <Column caption={"AssetId"} dataField="name" visible={false} allowEditing={true} formItem={{ label: { text: "AssetId" } }}>
        <RequiredRule />
      </Column>
      <Column dataField="description" caption="">
        <RequiredRule />
      </Column>
      <Column caption={"Parent"} dataField={"parentId"} calculateDisplayValue={"parent"} allowFiltering={true} width={200} visible={false}>
        <Lookup dataSource={nodeStore} valueExpr={"id"} displayExpr="name" />
      </Column>
      <Column caption={"Class"} dataField={"classId"} calculateDisplayValue="class" allowFiltering={true} width={200} visible={false}>
        <Lookup dataSource={classStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
      </Column>
      <Column
        dataField="value"
        caption="Value"
        visible={false}
        dataType="number"
        width={100}
        formItem={{ editorOptions: { format: `${currency()}#,##0.##`, showClearButton: true } }}
      />
      <Column visible={false} formItem={{ itemType: "empty" }} />
      1
      <Column
        caption={"Notes"}
        dataField={"notes"}
        allowFiltering={true}
        width={400}
        visible={false}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
      <Column type={"buttons"} width={85} visible={false} />
    </TreeList>
  );
}

const mapStateToProps = (state) => {
  return {
    scenarioName: "",
  };
};

export default connect(mapStateToProps, null)(withMemo(Component, ["render"]));
