import React, { useRef, useState } from "react";
import styles from "./coffunctionallocation.module.scss";
import { TreeList, Column, Lookup, Editing, Sorting, FilterRow, Popup, Position, Paging, Scrolling } from "devextreme-react/tree-list";
import { request, requestDownload } from "helper/http-client";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { Template } from "devextreme-react/core/template";
import { dict, now, notify } from "helper/global";
import { confirm } from "devextreme/ui/dialog";
import ProgressPanel from "components/progress-panel/progress-panel";
import FileUploader from "components/uploader/uploader";
import { connect } from "react-redux";
import * as actions from "redux/actions";

function Component(props) {
  //
  //stores

  const funtionallocationStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/cof/functionallocation`,
          loadOptions: loadOptions,
        });
      },
      insert: (data) => {
        return request({ url: `${url}/cof/functionallocation`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/cof/functionallocation`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/cof/functionallocation/${id}`, method: "Delete" });
      },
    }),
  };

  const businessValueStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cof/businessvalue`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cof/businessvalue/${id}` });
      },
    }),
  };

  const absFactorStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cof/AbsFactor`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cof/AbsFactor/${id}` });
      },
    }),
  };

  const rFactorStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cof/RFactor`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cof/RFactor/${id}` });
      },
    }),
  };

  // hooks
  const [fileLoader, setFileLoader] = useState(false);
  const treeList = useRef(null);

  // event handlers

  function onRowUpdating(e) {
    e.newData = { ...e.oldData, ...e.newData };
  }

  async function onDownloadClick() {
    var result = await confirm(`<i>${dict("Do you really want to download the data")}?</i>`, "Confirm");
    if (result) {
      try {
        await requestDownload({ url: `${url}/cof/functionallocation/download/1`, fileName: `IRIS CofFunctionalLocation ${now()}.csv` });
      } catch {}
    }
  }

  function hideFileLoader() {
    setFileLoader(false);
  }

  function onUploadClick() {
    setFileLoader(true);
  }

  async function onUploaded(fileId) {
    setFileLoader(false);
    props.progress({ name: "UPLOAD_CORFUNCTIONALLOCATION", url: `${url}/cof/functionallocation/upload/${fileId}` });
  }

  async function onSave() {
    var result = await confirm("<i>Do you really want to save the Cof values?</i>", "Confirm");
    if (result) {
      try {
        props.progress({ name: "RUN", url: `${url}/cof/save` });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  // render

  // function dropDownRender(data) {
  //   return (
  //     <DropDown data={data} showColumnHeaders={true} height={300} width={650}>
  //       <Column dataField="name" width={150}></Column>
  //       <Column dataField="description" width={400}></Column>
  //       <Column dataField="value" width={75} alignment={"left"}></Column>
  //     </DropDown>
  //   );
  // }

  function titleRender() {
    return (
      <div className="theme-grid-title">
        <div></div>
      </div>
    );
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      template: "titleRender",
      visible: true, //showTitle,
    });

    e.toolbarOptions.items.push(
      {
        location: "before",
        widget: "dxButton",
        visible: true, //allowAdding,
        options: {
          hint: "Save",
          icon: "fas fa-save",
          onClick: onSave,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        visible: true, //!!props.onUploaded,
        options: {
          hint: dict("Upload"),
          icon: "fas fa-cloud-upload",
          onClick: onUploadClick,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        visible: true, //!!props.allowDownload || !!allowDownloading,
        options: {
          hint: dict("Download"),
          icon: "fas fa-cloud-download",
          onClick: onDownloadClick,
        },
      }
    );

    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return (
    <ProgressPanel name="RUN">
      <ProgressPanel name="UPLOAD_CORFUNCTIONALLOCATION">
        <div className={styles.main}>
          <FileUploader fileLoaderVisible={fileLoader} hideFileLoader={hideFileLoader} onUploaded={onUploaded} />
          <div className={styles.row}>
            <div className={`${styles.col} theme-treelist`}>
              <TreeList
                ref={treeList}
                dataSource={funtionallocationStore}
                rootValue={null}
                selection={{ recursive: true }}
                showBorders={true}
                remoteOperations={true}
                // columnAutoWidth={true}
                selectedRowKeys={null}
                showRowLines={true}
                wordWrapEnabled={true}
                keyExpr="id"
                parentIdExpr="parentId"
                hasItemsExpr="hasChildren"
                height={"100%"}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode="widget"
                onToolbarPreparing={onToolbarPreparing}
                onRowUpdating={onRowUpdating}
              >
                <Editing mode={"popup"} allowUpdating={true} allowDeleting={true}>
                  <Popup title={"Functional Location"} showTitle={true} width={700} height={700}>
                    <Position my="center" at="center" of={window} />
                  </Popup>
                </Editing>
                <FilterRow visible={true} />
                <Paging pageSize={50} />
                <Sorting mode="multiple" />
                <Scrolling mode={"infinite"} showScrollbar={"always"} />
                {/* <Export enabled={false} fileName="iris_export" allowExportSelectedData={false} customizeExcelCell={props.customizeExcelCell} /> */}
                <Template name="titleRender" render={titleRender} />
                <Column dataField={"id"} visible={false} allowEditing={false} formItem={{ visible: false }} />
                <Column
                  caption={"Operation"}
                  name={"operation"}
                  calculateCellValue={() => "Update"}
                  width={100}
                  visible={false}
                  allowEditing={false}
                  formItem={{ visible: false }}
                />
                <Column caption={"Hierarchy"} dataField={"name"} width={300} />
                <Column caption={"Functional Location"} dataField={"fullName"} width={300} formItem={{ visible: false }} />
                <Column caption={"Description"} dataField={"description"} width={125} />
                <Column
                  caption={"Is Class"}
                  dataField={"isClass"}
                  width={100}
                  dataType={"boolean"}
                  allowEditing={false}
                  formItem={{ visible: false }}
                />
                <Column caption={"Class"} dataField={"class"} width={100} formItem={{ visible: false }} />
                {/* <Column caption={"Class"} dataField={"classId"} width={100}>
           <Lookup dataSource={classStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
         </Column> */}
                <Column caption={"Max Cof"} dataField={"maxCof"} dataType={"number"} width={100} formItem={{ visible: false }} />
                {/* <Column caption={"Business Value"} dataField={"cofBusinessValue"} width={150} formItem={{ visible: false }} /> */}
                <Column caption={"Business Value"} dataField={"businessValueId"} calculateDisplayValue={"businessValue"} width={150}>
                  <Lookup dataSource={businessValueStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
                </Column>
                <Column caption={"Abs Factor"} dataField={"absFactorId"} calculateDisplayValue="absFactor" width={150}>
                  <Lookup dataSource={absFactorStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
                </Column>
                <Column caption={"R Factor"} dataField={"rFactorId"} calculateDisplayValue="rFactor" width={200}>
                  <Lookup dataSource={rFactorStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
                </Column>
                <Column
                  caption={"Weighted Cof"}
                  dataField={"weightedCof"}
                  dataType={"number"}
                  format="0#"
                  width={100}
                  formItem={{ visible: false, editorOptions: { readOnly: true } }}
                />
                <Column visible={false} formItem={{ itemType: "empty" }} />
                <Column
                  caption={"Notes"}
                  dataField={"notes"}
                  allowFiltering={true}
                  width={400}
                  formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
                />
                <Column
                  type={"buttons"}
                  buttons={[
                    {
                      hint: "Resolve",
                      icon: "plus",
                      onClick: (e) => {
                        treeList.current.instance.addRow(e.row.data.id);
                      },
                    },
                    "edit",
                    "delete",
                  ]}
                  width={125}
                  fixed={true}
                />
              </TreeList>
            </div>
          </div>
        </div>
      </ProgressPanel>
    </ProgressPanel>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    progress: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
