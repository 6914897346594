import React, { useEffect } from "react";
import Layout from "layout/layout";
import { Switch, Route, withRouter, useLocation, Redirect, useHistory } from "react-router-dom";
import { navigation } from "./navigation";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { url } from "config.js";
import { withMemo } from "helper/global";
import Lookup from "./lookup/lookup";
import Input from "./input/input";
import Admin from "./admin/admin";
import Indicator from "components/indicator/indicator";
import Error from "components/error/error";

function Component(props) {
  //
  //
  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    let jwt = query.get("jwt");
    props.authenticate(`${url}/cof`, jwt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      if (query.has("jwt")) {
        query.delete("jwt");
        history.replace({
          search: query.toString(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAuthenticated]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  if (props.isAuthenticated === null) {
    return <Indicator />;
  }

  if (!props.isAuthenticated) {
    return <Error text={"Unauthorized Access"} />;
  }

  return (
    props.isAuthenticated && (
      <Switch>
        <Route
          path="/klient"
          render={() => {
            return <MemorizedLayout isAdmin={props.isAdmin} />;
          }}
        />
        <Redirect to="/klient" />
      </Switch>
    )
  );
}

const MemorizedLayout = withMemo((props) => {
  function setNavigation() {
    const nav = JSON.parse(JSON.stringify(navigation));
    // const ada = nav.find((i) => i.text === "Cof Calculator");
    // let admin = ada.items.find((i) => i.text === "Admin");
    //admin.visible = props.isAdmin;
    return nav;
  }

  return (
    <Layout navigation={setNavigation()} selectedPath="input">
      <Switch>
        <Route exact path={["/klient", "/klient/cof"]} component={Input} />
        <Route path={"/klient/cof/lookup"} component={Lookup} />
        <Route path={"/klient/cof/input"} component={Input} />
        <Route path={"/klient/cof/admin"} component={Admin} />
      </Switch>
    </Layout>
  );
}, []);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.user.roleId === 1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (url, jwt) => dispatch(actions.authenticate(url, jwt)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
