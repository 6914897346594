import React from "react";
import { Route, Switch } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./cond.module.scss";
import { selectedTabItem } from "helper/global";
import Data from "./data/data";
import Log from "./log/log";

function Component(props) {
  //
  //

  const tabItems = [
    {
      text: "Log",
      icon: "fad fa-chart-line",
      path: "/log",
    },
    {
      text: "Data",
      icon: "fad fa-database",
      path: "/data",
    },
  ];

  //functions

  function changeView(e) {
    props.history.push(`/klient/kon/cond${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={`${styles.tabs} `}>
        <Tabs
          tabItems={tabItems}
          selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)}
          changeView={changeView}
          className={"dx-theme-border-color"}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/kon", "/klient/kon/cond", "/klient/kon/cond/log"]} component={Log} />
          <Route exact path="/klient/kon/cond/data" component={Data} />
        </Switch>
      </div>
    </div>
  );
}

export default Component;
