import React from "react";
import styles from "./error.module.scss";
import { faWindowClose } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "devextreme-react";

function Component(props) {
  return (
    <div className={styles.main}>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </div>
      <div className={styles.title}>Oops..!</div>
      <div className={styles.text}>{props.text || "Something went wrong..."}</div>
      <Button
        type={"normal"}
        stylingMode="outlined"
        width={100}
        onClick={() => {
          var win = window.open("about:blank", "_self");
          win.close();
        }}
      >
        <div className={styles.button}>
          <div className={styles.button_text}>Close</div>
          <div className={styles.button_icon}>
            <FontAwesomeIcon icon={faWindowClose} />
          </div>
        </div>
      </Button>
    </div>
  );
}

export default Component;
