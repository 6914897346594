import React, { useRef } from "react";
import { FileUploader as DxFileUploader, Popup } from "devextreme-react";
import { api, url } from "config.js";
import ScrollView from "devextreme-react/scroll-view";
import { dict } from "helper/global";

function FileUploader(props) {
  // hooks

  const fileUploader = useRef();

  // event handlers

  function onUploaded(e) {
    props.onUploaded && props.onUploaded(e.request.responseText);
  }

  function onHiding(e) {
    props.hideFileLoader();
  }

  // render

  return (
    <div>
      <Popup
        className={"popup"}
        visible={props.fileLoaderVisible}
        onHiding={onHiding}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={dict("File Uploader")}
        width={600}
        height={600}
      >
        <ScrollView>
          <DxFileUploader
            ref={fileUploader}
            uploadMode={"useButtons"}
            multiple={props.multiple || false}
            allowedFileExtensions={props.allowedFileExtensions || [".csv"]}
            uploadUrl={props.uploadUrl ? `${api}/${props.uploadUrl}` : `${api}/${url}/temporarystorage/upload`}
            onUploaded={onUploaded}
          />
          <span className="note">
            {`${dict("Allowed file extensions")}: `}
            <span>{(props.allowedFileExtensions && props.allowedFileExtensions.map((i) => `  ${i}`)) || ".csv"}</span>
          </span>
        </ScrollView>
      </Popup>
    </div>
  );
}

export default FileUploader;
