import React from "react";
import { Route, Switch } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./input.module.scss";
import { selectedTabItem } from "helper/global";
import { dict } from "helper/global";
import ClassImpactFactor from "./cofclass/cofclass";
import ClassNode from "./cofscenarionode/cofscenarionode";
import CofFuctionalLocaiton from "./coffunctionallocation/coffunctionallocation";

function Component(props) {
  const tabItems = [
    {
      text: dict("Max Cof"),
      icon: "fad fa-users-class",
      path: "/cofclass",
    },
    // {
    //   text: dict("Weighted Cof"),
    //   icon: "fad fad fa-weight-hanging",
    //   path: "/cofnode",

    // },
    {
      text: dict("Weighted Cof"),
      icon: "fad fad fa-weight-hanging",
      path: "/coffunctionallocation",
    },
  ];

  //functions

  function changeView(e) {
    props.history.push(`/klient/cof/input${e.path}`);
  }

  return (
    <div className={styles.main}>
      <div className={`${styles.tabs} `}>
        <Tabs
          tabItems={tabItems}
          selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)}
          changeView={changeView}
          className={"dx-theme-border-color"}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/cof", "/klient/cof/input", "/klient/cof/input/cofclass"]} component={ClassImpactFactor} />
          <Route exact path="/klient/cof/input/cofnode" component={ClassNode} />
          <Route exact path="/klient/cof/input/coffunctionallocation" component={CofFuctionalLocaiton} />
        </Switch>
      </div>
    </div>
  );
}

export default Component;
