import React, { useRef } from "react";
import styles from "./updated.module.scss";
import { withMemo, useRefState } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import { confirm } from "devextreme/ui/dialog";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import JobPanel from "components/jobpanel/jobpanel";
import LuieTrap from "./datasets/luietrap";
import { alert } from "devextreme/ui/dialog";

function Component(props) {
  //
  // stores
  var statusStore = [
    { id: 0, name: "None" },
    { id: 1, name: "New" },
    { id: 2, name: "Old" },
    { id: 3, name: "Created" },
    { id: 4, name: "Updated" },
    { id: 5, name: "Warning" },
    { id: 6, name: "Superseded" },
    { id: 7, name: "Ignored" },
  ];

  // hooks
  const initialState = { id: null };
  const [state, setState] = useRefState(initialState);

  // functions

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async function rollback(refresh) {
    var result = await confirm("<i>Do you really want to rollback the selected job?</i>", "Confirm");
    if (result) {
      try {
        props.run({ name: "RUN", url: `${url}/kon/rollback/log/${state.current.id}` });
      } catch (e) {
        alert(`<div style="color:red;font-weight: bold;" >${e.message}</div>`, "Error");
      }
    }
  }

  // event handlers

  function onCellPrepared(e) {
    if (e.rowType === "data" && e.column.dataField) {
      if (e.data[`is${capitalizeFirstLetter(e.column.dataField)}Updated`] === 1) e.cellElement.style.color = "#bb86fc";
    }

    if (e.rowType === "data" && (e.column.dataField === "statusId" || e.column.dataField === "notes")) {
      if (e.data.statusId === 5) e.cellElement.style.color = "red";
    }
  }

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  function dataSetRender() {
    switch (props.jobId) {
      case 2:
        return <LuieTrap logId={state.current.id} onCellPrepared={onCellPrepared} statusStore={statusStore} />;

      default:
        return null;
    }
  }

  return (
    <JobPanel name={"RUN"}>
      <div className={styles.main}>
        <div className={styles.log}>
          <LogGrid jobId={props.jobId} rollback={rollback} onSelectionChanged={onSelectionChanged} />
        </div>
        <div className={styles.dataset}>{dataSetRender()}</div>
      </div>
    </JobPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.startJob(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const LogGrid = withMemo(
  (props) => {
    //
    // stores
    const logStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          if (!props.jobId) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/kon/log/${props.jobId}`,
            loadOptions: loadOptions,
          });
        },
      }),
    };

    // hooks

    const dataGrid = useRef(null);

    // event Handlers

    function onInitialized(e) {
      dataGrid.current = e.component;
      props.onInitialized && props.onInitialized(e);
    }

    // render

    return (
      <Grid
        title={"Log"}
        dataSource={logStore}
        showButtons={false}
        selection={{ mode: "single" }}
        resetRowSelectionOnLoad={true}
        onSelectionChanged={props.onSelectionChanged}
        allowExpanding={true}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={false}
        onInitialized={onInitialized}
      >
        <Column caption={"LogId"} dataField={"id"} width={100} sortOrder={"desc"} />
        <Column caption={"Job"} dataField={"job"} width={200} />
        <Column caption={"DateTime"} dataField={"dateTime"} dataType={"date"} format={"yyyy-MM-dd HH:mm:ss"} width={200} />
        <Column caption={"Status"} dataField={"status"} width={200} />
        <Column caption={"Message"} dataField={"message"} />
        <Column
          type={"buttons"}
          buttons={[
            {
              hint: "Rollback",
              icon: "undo",
              onClick: props.rollback,
            },
          ]}
          width={75}
          fixed={true}
        />
      </Grid>
    );
  },
  ["jobId"]
);
